import React, { useContext, useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import queryString from "query-string";
import { Formik, useFormikContext } from "formik";
import { navigate } from "gatsby";
import styled from "styled-components";
import ContentBox from "../components/ContentBox";
import Form from "../components/Form";
import FormField from "../components/FormField";
import Label from "../components/Label";
import Input from "../components/Input";
import Button from "../components/buttons/Button";
import FormHint from "../components/FormHint";
import Link from "../components/Link";
import ActionBar from "../components/OldActionBar";
import DangerButton from "../components/buttons/DangerButton";
import Modal from "../components/Modal";
import FormError from "../components/FormError";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Drag } from "grommet-icons/es6";
import PageWrapper from "../components/PageWrapper";
import UserContext from "../contexts/UserContext";
import Select from "../components/Select";
import {
  APPROVED,
  ARCHIVED,
  DRAFT,
  friendlyStatus,
  REVIEW,
} from "../constants";
import { REGULAR_TEXT } from "../colors";
import DarkPageHeading from "../components/DarkPageHeading";

const GET_ROUND_TEMPLATE = gql`
  query GetRoundTemplate($id: ID!) {
    roundTemplate: getRoundTemplate(id: $id) {
      id
      title
      status
      questionTemplates {
        id
        number
        text
        answer
      }
    }
  }
`;

const SAVE_ROUND_TEMPLATE = gql`
  mutation SaveRoundTemplate($title: String!, $id: ID, $status: String) {
    saveRoundTemplate(title: $title, id: $id, status: $status) {
      roundTemplate {
        id
      }
    }
  }
`;

const REORDER_QUESTION_TEMPLATES = gql`
  mutation ReorderQuestionTemplates(
    $roundTemplateId: ID!
    $questionTemplateId: ID!
    $newNumber: Int!
  ) {
    reorderQuestionTemplates(
      roundTemplateId: $roundTemplateId
      questionTemplateId: $questionTemplateId
      newNumber: $newNumber
    ) {
      roundTemplate {
        id
        questionTemplates {
          id
          number
          text
        }
      }
    }
  }
`;

const DELETE_ROUND_TEMPLATE = gql`
  mutation DeleteRoundTemplate($id: ID!) {
    deleteRoundTemplate(id: $id) {
      success
    }
  }
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const QuestionHeading = styled.div`
  font-size: 20px;
  margin-bottom: 15px;
`;

const QuestionListing = styled.div`
  margin-bottom: 10px;
  svg {
    opacity: 0;
  }

  ${({ disabled }) =>
    !disabled &&
    `
    :hover svg {
      opacity: 1;
    }
  `}
`;

const DragWrapper = styled.span`
  padding: 15px 5px;

  :hover {
    cursor: grab;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;
`;

const UneditableValue = styled.div`
  color: ${REGULAR_TEXT};
  font-weight: bold;
`;

const RoundTemplateEditor = ({
  id,
  questionTemplates,
  originalValues,
  onReorder,
  onDelete,
}) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    setFieldValue,
    submitForm,
    isSubmitting,
  } = useFormikContext();
  const { currentUser } = useContext(UserContext);
  const isSuperuser = currentUser && currentUser.isSuperuser;

  const [showDeleteModal, setShowDeleteModal] = useState();
  const [isDeleting, setIsDeleting] = useState();

  const isChanged = () =>
    values.title !== originalValues.title ||
    values.status !== originalValues.status;

  const handleDragEnd = async (result) => {
    const questionTemplateId = result.draggableId;
    const newNumber = result.destination.index + 1;

    await onReorder(questionTemplateId, newNumber);
  };

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
  };

  const handleConfirmDelete = async () => {
    setIsDeleting(true);
    await onDelete();
  };

  return (
    <ContentBox direction="column">
      <DarkPageHeading>{!id ? "Create Round" : "Edit Round"}</DarkPageHeading>
      <FormWrapper>
        <Form>
          <FormField>
            <Label htmlFor="title">Title</Label>
            <Input
              type="text"
              name="title"
              autoComplete="off"
              value={values.title}
              onChange={handleChange}
            />
            {errors.title && touched.title && (
              <FormError>{errors.title}</FormError>
            )}
          </FormField>
          <FormField>
            <Label>Status</Label>
            {isSuperuser && (
              <Select
                name="status"
                value={{
                  value: values.status,
                  label: friendlyStatus[values.status],
                }}
                options={[
                  { value: DRAFT, label: friendlyStatus[DRAFT] },
                  { value: REVIEW, label: friendlyStatus[REVIEW] },
                  { value: APPROVED, label: friendlyStatus[APPROVED] },
                  { value: ARCHIVED, label: friendlyStatus[ARCHIVED] },
                ]}
                onChange={(option) => setFieldValue("status", option.value)}
              />
            )}
            {!isSuperuser && <UneditableValue>{values.status}</UneditableValue>}
          </FormField>

          <QuestionHeading>Questions</QuestionHeading>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {questionTemplates.map((qt, index) => (
                    <Draggable key={qt.id} draggableId={qt.id} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <QuestionListing>
                            <DragWrapper>
                              <Drag size="small" />
                            </DragWrapper>

                            <Link
                              key={qt.id}
                              to={`/create-edit-question-template?roundTemplateId=${id}&questionNumber=${qt.number}`}
                            >
                              Question {qt.number}: {qt.text}
                            </Link>
                          </QuestionListing>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <ButtonWrapper>
            <Link to={`/create-edit-question-template?roundTemplateId=${id}`}>
              <Button disabled={!id}>
                {questionTemplates.length === 0
                  ? "Add First Question"
                  : "Add a Question"}
              </Button>
            </Link>
            {!id && <FormHint>Save round in order to add questions</FormHint>}
          </ButtonWrapper>
        </Form>
      </FormWrapper>
      <ActionBar>
        <Button
          size="large"
          disabled={!isChanged() || isSubmitting}
          onClick={() => submitForm()}
        >
          {isSubmitting ? "Saving" : "Save Round"}
        </Button>
        <Link to={"/question-database"}>
          <Button size="large">Close</Button>
        </Link>
        <DangerButton size="large" disabled={!id} onClick={handleDeleteClick}>
          Delete Round
        </DangerButton>
      </ActionBar>
      {showDeleteModal && (
        <Modal
          title="Delete Round"
          text="Delete this round and all the related questions?"
          buttons={
            <>
              <DangerButton
                size="large"
                disabled={isDeleting}
                onClick={handleConfirmDelete}
              >
                {isDeleting ? "Deleting" : "Delete"}
              </DangerButton>
              <Button size="large" onClick={handleCancelDelete}>
                Cancel
              </Button>
            </>
          }
        />
      )}
    </ContentBox>
  );
};

export default function CreateEditRound({ location }) {
  const { id } = queryString.parse(location.search);

  const { data, refetch } = useQuery(GET_ROUND_TEMPLATE, {
    variables: {
      id,
    },
    skip: !id,
  });

  const [saveRoundTemplate] = useMutation(SAVE_ROUND_TEMPLATE);
  const [reorderQuestionTemplates] = useMutation(REORDER_QUESTION_TEMPLATES);
  const [deleteRoundTemplate] = useMutation(DELETE_ROUND_TEMPLATE);

  if (id && !data) {
    return null;
  }

  const originalValues = (data && data.roundTemplate) || {};

  const initialValues = {
    title: data && data.roundTemplate && data.roundTemplate.title,
    status: data && data.roundTemplate && data.roundTemplate.status,
  };

  const validate = (values) => {
    const errors = {};
    if (!values.title) {
      errors.title = "You must enter a title";
    }
    return errors;
  };

  const handleSubmit = async (values) => {
    const result = await saveRoundTemplate({
      variables: {
        id,
        title: values.title,
        status: values.status,
      },
    });
    const newRoundTemplateId = result.data.saveRoundTemplate.roundTemplate.id;
    await navigate(`/create-edit-round-template?id=${newRoundTemplateId}`);
    await refetch({ id: newRoundTemplateId });
  };

  const handleReorderQuestionTemplates = async (
    questionTemplateId,
    newNumber
  ) => {
    await reorderQuestionTemplates({
      variables: {
        roundTemplateId: id,
        questionTemplateId,
        newNumber,
      },
    });
    await refetch();
  };

  const handleDelete = async () => {
    await deleteRoundTemplate({
      variables: {
        id,
      },
    });

    await navigate("/question-database");
  };

  return (
    <PageWrapper>
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={handleSubmit}
      >
        <RoundTemplateEditor
          id={id}
          questionTemplates={
            (data &&
              data.roundTemplate &&
              data.roundTemplate.questionTemplates) ||
            []
          }
          originalValues={originalValues}
          onReorder={handleReorderQuestionTemplates}
          onDelete={handleDelete}
        />
      </Formik>
    </PageWrapper>
  );
}
